<script>
  import { Router } from "@sveltech/routify";
  import { routes } from "../.routify/routes";
</script>

<style  global>@font-face {
  font-family: "signifier-light";
  src: url("/fonts/signifier-web-light.eot");
  src: url("/fonts/signifier-web-light.eot?#iefix") format("embedded-opentype"),
    url("/fonts/signifier-web-light.woff2") format("woff2"),
    url("/fonts/signifier-web-light.woff") format("woff"),
    url("/fonts/signifier-web-light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "signifier-regular";
  src: url("/fonts/signifier-web-regular.eot");
  src: url("/fonts/signifier-web-regular.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/signifier-web-regular.woff2") format("woff2"),
    url("/fonts/signifier-web-regular.woff") format("woff"),
    url("/fonts/signifier-web-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "signifier-regular-italic";
  src: url("/fonts/signifier-web-regular-italic.eot");
  src: url("/fonts/signifier-web-regular-italic.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/signifier-web-regular-italic.woff2") format("woff2"),
    url("/fonts/signifier-web-regular-italic.woff") format("woff"),
    url("/fonts/signifier-web-regular-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "signifier-medium";
  src: url("/fonts/signifier-web-medium.eot");
  src: url("/fonts/signifier-web-medium.eot?#iefix") format("embedded-opentype"),
    url("/fonts/signifier-web-medium.woff2") format("woff2"),
    url("/fonts/signifier-web-medium.woff") format("woff"),
    url("/fonts/signifier-web-medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "signifier-bold";
  src: url("/fonts/signifier-web-bold.eot");
  src: url("/fonts/signifier-web-bold.eot?#iefix") format("embedded-opentype"),
    url("/fonts/signifier-web-bold.woff2") format("woff2"),
    url("/fonts/signifier-web-bold.woff") format("woff"),
    url("/fonts/signifier-web-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "signifier-black";
  src: url("/fonts/signifier-web-black.eot");
  src: url("signifier-web-black.eot?#iefix") format("embedded-opentype"),
    url("/fonts/signifier-web-black.woff2") format("woff2"),
    url("/fonts/signifier-web-black.woff") format("woff"),
    url("/fonts/signifier-web-black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:global(h1),
:global(h2),
:global(h3),
:global(h4),
:global(p),
:global(blockquote) {
  margin: 0;
  padding: 0;
}

:global(html),
:global(body) {
  position: relative;
  width: 100%;
  height: 100%;
}

:global(body) {
  color: #333;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  font-family: "signifier-bold", Georgia, serif;
}

:global(a) {
  color: rgb(0, 100, 200);
  text-decoration: none;
}

:global(a:hover) {
  text-decoration: underline;
}

:global(a:visited) {
  color: rgb(0, 80, 160);
}

:global(label) {
  display: block;
}

:global(input),
:global(button),
:global(select),
:global(textarea) {
  font-family: inherit;
  font-size: inherit;
  padding: 0.4em;
  margin: 0 0 0.5em 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px;
}

:global(input:disabled) {
  color: #ccc;
}

:global(input[type="range"]) {
  height: 0;
}

:global(button) {
  color: #333;
  background-color: #f4f4f4;
  outline: none;
}

:global(button:disabled) {
  color: #999;
}

:global(button:not(:disabled):active) {
  background-color: #ddd;
}

:global(button:focus) {
  border-color: #666;
}</style>

<Router {routes} />