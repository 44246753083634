<script>
  import RoutifyIntro from "./example/_components/RoutifyIntro.svelte";
  import { metatags } from "@sveltech/routify";
  metatags.title = "Object Oriented";
  metatags.description = "Interior design practice";
</script>

<style>
  :global(body) {
    background-color: #f0ebf4;
    padding: 10vw;
  }
  .main {
    display: grid;
    grid-template-rows: 25vh 1fr 1fr 1fr;
    grid-template-areas:
      "logo .... .... ...."
      "blurb blurb blurb blurb"
      "quote quote quote quote"
      "contact contact contact contact";
    justify-content: center;
    font-family: "signifier-regular", Georgia, serif;
    font-size: 1.5rem;
  }
  h1 {
    grid-area: logo;
    line-height: 140%;
  }
  .main p {
    grid-area: blurb;
    color: rgba(0, 0, 0, 0.8);
  }
  blockquote {
    padding-left: 2rem;
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.5);
    border-left: solid 8px #d5ba60;
    grid-area: quote;
  }
  blockquote p {
    font-family: "signifier-regular";
    margin: 0;
    padding: 0;
  }
  blockquote .attribution {
    font-family: "signifier-regular-italic", Georgia, serif;
  }
  .email {
    grid-area: contact;
    margin-top: 4rem;
  }
  .yellow {
    color: #fcc513;
  }
  .mustard {
    color: #d5ba60;
  }</style>

<div class="main">
  <h1><span class="yellow">Object</span><br /><span class="mustard">Oriented</span></h1>
  <p>
    Object Oriented is an interior design practice with a focus on residences.
    Our belief is that the home should be designed by the people who live in it.
    A home should be furnished in concert with the patterns that flow naturally
    through it, to support our routines, indulge our mess, soothe our troubles,
    and embrace our ordinary comforts.
  </p>
  <blockquote>
    <p>
      The word "comfortable" is more profound than people usually realize. The
      mystery of genuine comfort goes far beyond the simple idea that the word
      first seems to mean. Places which are comfortable are comfortable because
      they have no inner contradictions, because there is no little restlessness
      disturbing them.
    </p>
    <br />— Christopher Alexander, <span class="attribution">
      The Timeless Way of Building
    </span>
  </blockquote>
  <div class="email">
    <p>To get in touch, reach us at inquiries@oo.land</p>
  </div>
</div>
